<template>
  <div>
    <section>
      <b-form-row v-if="restrictMultipleCitySelection">
        <b-col>
          <b-alert show variant="danger">{{ DISPLAY_MESSAGES.MULTIPLE_CITIES_EMAIL_SETTINGS_ERROR }}</b-alert>
        </b-col>
      </b-form-row>
      <b-form v-else id="emailSettings-from" name="emailSettings-from" autocomplete="off" @submit.prevent="onSubmitEmailSettings" novalidate>
        <b-form-row>
          <b-col cols="12" sm="7" md="6" lg="4">
            <b-form-group>
              <div class="floating-select-field">
                <b-form-select
                  id="email-settings-email-type"
                  class="form-control"
                  :options="emailTemplates"
                  text-field="templateType"
                  value-field="id"
                  v-model.number="emailSettings.emailTemplateId"
                  @input="emailSettings.emailTemplateId = $event"
                  @change="getEmailSettings"
                  :class="{
                    'is-value-exist': emailSettings.emailTemplateId != null,
                  }"
                >
                </b-form-select>
                <label for="email-settings-email-type">Email type</label>
                <b-form-invalid-feedback class="d-block" v-if="!emailSettings.emailTemplateId && formSubmitted">Email type required.</b-form-invalid-feedback>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="7" md="6" lg="4">
            <b-form-group>
              <div class="floating-input-field">
                <b-form-input id="email-settings-display-name" type="text" placeholder=" " v-model="emailSettings.displayName" required></b-form-input>
                <label for="email-settings-display-name">Display Name</label>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="7" md="6" lg="4">
            <b-form-group>
              <div class="floating-input-field">
                <b-form-input
                  id="email-settings-display-email"
                  type="text"
                  :disabled="isDefaultCulture"
                  placeholder=" "
                  v-model="emailSettings.displayEmail"
                  required
                ></b-form-input>
                <label for="email-settings-display-email">Display email <InheritText v-if="isDefaultCulture" /></label>
                <b-form-invalid-feedback class="d-block" v-if="!emailSettings.displayEmail && formSubmitted">Display email required.</b-form-invalid-feedback>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="7" md="6" lg="4">
            <b-form-group>
              <div class="floating-input-field">
                <b-form-input id="email-settings-display-subject" type="text" v-model="emailSettings.subject" placeholder=" " required></b-form-input>
                <label for="email-settings-display-subject">Display subject line</label>
                <b-form-invalid-feedback class="d-block" v-if="!emailSettings.subject && formSubmitted">Display subject line required.</b-form-invalid-feedback>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="11" md="10" lg="10" class="mt-3">
            <b-form-group>
              <p>Template</p>
              <div class="d-flex justify-content-between">
                <div><InfoCircelYellow class="mr-1 mt-n1" />Please note that some of the email clients may not support .svg and icon images.</div>
                <TokenHelp v-if="emailSettings.emailTemplateId" :token-type="getTokenHelpProp" />
              </div>
              <editor :api-key="tinyEditorOptions.apiKey" :init="tinyEditorOptions.initConfig" v-model="emailSettings.templateContent" />
              <b-form-invalid-feedback class="d-block" v-if="!emailSettings.templateContent && formSubmitted">Template required.</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row class="my-4">
          <b-col>
            <b-button type="submit" id="GS-CTZB-SETE-SBM" v-activeBlur variant="primary" :disabled="saveLoadingIcon"
              >Save <b-spinner v-if="saveLoadingIcon" label="Spinning" small class="ml-2"></b-spinner
            ></b-button>
            <b-button type="button" id="GS-CTZB-SETE-CNL" v-activeBlur class="ml-3" @click="resetEmailSettings(null)" variant="outline-secondary">Cancel</b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Editor from '@tinymce/tinymce-vue'
import { useValidateFields } from '@/composables/useValidateFields'
import { TinyEditorOptions, DISPLAY_MESSAGES } from '../../utilities/constants'
import { cloneDeep } from 'lodash'
import InfoCircelYellow from '../../assets/svg/info-circle-yellow.svg'
export default {
  name: 'EmailSettings',
  setup() {
    const { scrollToErrorMessage } = useValidateFields()

    return { scrollToErrorMessage }
  },
  components: {
    editor: Editor,
    InfoCircelYellow,
    TokenHelp: () => import('../common/token-help/TokenHelp.vue'),
    InheritText: () => import('@/components/common/InheritText.vue'),
  },
  data() {
    return {
      formSubmitted: false,
      saveLoadingIcon: false,
      tinyEditorOptions: TinyEditorOptions,
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
      cloneEmailSettings: null,
      emailSettings: {
        emailTemplateId: null,
        displayName: null,
        displayEmail: 'noreply@esolutionsgroup.ca',
        subject: null,
        templateContent: '',
        culture: null,
      },
    }
  },
  computed: {
    restrictMultipleCitySelection() {
      return (this.selectedTenant.length > 1 || this.selectedTenant.some((tenant) => tenant.tenantID === 0)) && !this.isEditPage
    },
    getTokenHelpProp() {
      if (this.emailSettings.emailTemplateId) {
        if (this.emailSettings.emailTemplateId === 1) {
          return 'verificationEmail'
        }
        if (this.emailSettings.emailTemplateId === 2) {
          return 'passwordRest'
        }
        if (this.emailSettings.emailTemplateId === 3) {
          return 'notificationEmail'
        }
      }
      return null
    },
    isDefaultCulture() {
      return this.selectedLanguage !== this.defaultLanguage
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
      emailTemplates: (state) => state.settings.emailTemplates,
      selectedLanguage: (state) => state.languages.selectedLanguage,
      defaultLanguage: (state) => state.languages.defaultLanguage,
    }),
  },
  mounted() {
    if (this.emailTemplates && !this.emailTemplates.length) {
      this.getEmailTemplates()
    }
    this.cloneEmailSettings = cloneDeep(this.emailSettings)
  },
  methods: {
    getEmailTemplates() {
      this.$store.dispatch('settings/getEmailTemplates')
    },
    getEmailSettings(templateId) {
      const payload = {
        templateId,
        culture: this.selectedLanguage,
      }
      this.$store
        .dispatch('settings/getEmailSettings', payload)
        .then((response) => {
          if (response && response.data) {
            this.emailSettings = response.data
          } else {
            this.resetEmailSettings(templateId)
          }
        })
        .catch(() => {
          this.showToaster(false, 'FAILED', 'danger')
        })
    },

    showToaster(message, key, type) {
      this.$store.commit('common/setCustomToastData', {
        message: message,
        key: key,
        type: type,
      })
    },
    validateEmailSettingsForm() {
      const emailType = !!this.emailSettings.emailTemplateId
      const email = !!this.emailSettings.displayEmail
      const subject = !!this.emailSettings.subject
      const template = !!this.emailSettings.templateContent
      this.scrollToErrorMessage()
      return emailType && email && subject && template
    },
    onSubmitEmailSettings() {
      this.formSubmitted = true
      if (this.validateEmailSettingsForm()) {
        this.saveLoadingIcon = true
        this.emailSettings.tenantID = this.selectedTenant[0].tenantID
        this.emailSettings.culture = this.selectedLanguage
        this.$store
          .dispatch('settings/updateEmailSetting', this.emailSettings)
          .then((response) => {
            this.saveLoadingIcon = false
            if (response.data) {
              this.showToaster(false, 'SAVE_EMAIL_SETTIGNS', 'success')
            } else {
              this.showToaster(false, 'FAILED', 'danger')
            }
          })
          .catch(() => {
            this.saveLoadingIcon = false
            this.showToaster(false, 'FAILED', 'danger')
          })
      }
    },
    resetEmailSettings(templateId) {
      this.emailSettings = cloneDeep(this.cloneEmailSettings)
      this.emailSettings.emailTemplateId = templateId
    },
  },
  watch: {
    selectedTenant(newValue, oldValue) {
      if (this.emailSettings && this.emailSettings.emailTemplateId && newValue.length === 1) {
        this.getEmailSettings(this.emailSettings.emailTemplateId)
      }
    },
    selectedLanguage(newValue, oldValue) {
      if (this.emailSettings && this.emailSettings.emailTemplateId) {
        this.getEmailSettings(this.emailSettings.emailTemplateId)
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
